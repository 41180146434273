import RecordServices from "../services/record.service"
import { setAlert } from "./alert"
import {
	ADD_RECORD,
	CLEAR_ALL_RECORDS,
	DELETE_RECORDS,
	GET_RECORD,
	GET_RECORDS,
	GET_RECORDS_WITH_PAGINATION,
	INIT_RECORDS,
	PREPARE_EXPORT,
	RECORD_ERROR,
	SELECT_RECORDS,
	UPDATE_CLIENT_ACCESS,
	UPDATE_MANY_RECORDS,
	UPDATE_RECORD,
} from "./types"

export const getRecords = (url) => async (dispatch) => {
	try {
		dispatch(initRecords())
		const res = await RecordServices.getAllRecords(url)
		dispatch({ type: GET_RECORDS, payload: res.data })
	} catch (err) {
		dispatch({ type: RECORD_ERROR, payload: err.message })
	}
}

export const getRecordsWithPagination =
	(url, limit, skip, searchText) => async (dispatch) => {
		try {
			dispatch(initRecords())
			const params = { limit, skip, search_text: searchText }
			const res = await RecordServices.getAllRecordsWithPagination(url, params)
			dispatch({ type: GET_RECORDS_WITH_PAGINATION, payload: res.data })
		} catch (err) {
			dispatch({ type: RECORD_ERROR, payload: err.message })
		}
	}

export const getRecord = (url, id) => async (dispatch) => {
	try {
		const res = await RecordServices.getRecord(url + id)
		dispatch({ type: GET_RECORD, payload: { id: id, ...res.data } })
	} catch (err) {
		dispatch({ type: RECORD_ERROR, payload: err.message })
	}
}

export const addRecordWithPagination =
	(object, formData) => async (dispatch) => {
		try {
			const result = await RecordServices.addRecord(object.addUrl, formData)
			if (result.data.status === "success") {
				dispatch(setAlert(result.data.response, "success", "AddRecord", 1000))
				dispatch({ type: INIT_RECORDS })
				const params = { limit: 10, skip: 0, search_text: "" }
				const res = await RecordServices.getAllRecordsWithPagination(
					object.findAllUrl,
					params
				)
				dispatch({ type: GET_RECORDS_WITH_PAGINATION, payload: res.data })
				return true
			}
		} catch (err) {
			dispatch({ type: RECORD_ERROR, payload: err.message })
			dispatch(setAlert("Failed to add new record!", "danger", "AddRecord"))
			return false
		}
	}

export const addRecord = (object, formData) => async (dispatch) => {
	try {
		const result = await RecordServices.addRecord(object.addUrl, formData)
		if (result.data.status === "success") {
			const res = await RecordServices.getAllRecords(object.findAllUrl)
			dispatch({ type: ADD_RECORD, payload: res.data })
			dispatch(setAlert(result.data.response, "success", "AddRecord", 1000))
			return true
		}
	} catch (err) {
		dispatch({ type: RECORD_ERROR, payload: err.message })
		dispatch(setAlert("Failed to add new record!", "danger", "AddRecord"))
		return false
	}
}

export const updateRecord = (object, id, formData) => async (dispatch) => {
	try {
		const result = await RecordServices.updateRecord(
			object.updateUrl + id,
			formData
		)
		const payload = {
			...formData,
			id,
		}
		dispatch({ type: UPDATE_RECORD, payload })
		dispatch(setAlert(result.data.response, "success", "UpdateRecord", 1000))
		return true
	} catch (err) {
		dispatch({ type: RECORD_ERROR, payload: err.message })
		dispatch(setAlert("Failed to update record!", "danger", "UpdateRecord"))
		return false
	}
}

export const updateManyRecords = (object, formData) => async (dispatch) => {
	try {
		const result = await RecordServices.updateManyRecords(
			object.updateManyUrl,
			formData
		)
		const data = formData.payload
		Object.keys(data).forEach((key) => {
			if (
				data[key] === "" ||
				data[key] === [] ||
				data[key] === {} ||
				data[key] === null ||
				data[key] === undefined
			) {
				delete data[key]
			}
		})
		dispatch({ type: UPDATE_MANY_RECORDS, payload: formData })
		dispatch(setAlert(result.data.response, "success", "UpdateRecord", 1000))
		return true
	} catch (err) {
		dispatch({ type: RECORD_ERROR, payload: err.message })
		dispatch(setAlert("Failed to update records!", "danger", "UpdateRecord"))
		return false
	}
}

export const selectRecords = (selectedRecords) => (dispatch) => {
	dispatch({ type: SELECT_RECORDS, payload: selectedRecords })
}

export const initRecords = () => (dispatch) => {
	dispatch({ type: INIT_RECORDS })
}

export const removeRecords = (object, selectedRecords) => async (dispatch) => {
	const formData = { [object.delField]: selectedRecords }
	try {
		const res = await RecordServices.deleteRecords(object.delUrl, formData)
		dispatch({ type: DELETE_RECORDS, payload: selectedRecords })
		dispatch(setAlert(res.data.response, "success", "DeleteRecord", 2000))
		return true
	} catch (err) {
		dispatch({ type: RECORD_ERROR, payload: err.message })
		dispatch(
			setAlert("Failed to delete selected records!", "danger", "DeleteRecord")
		)
		return false
	}
}

export const prepareExport = (columns, data) => (dispatch) => {
	dispatch({ type: PREPARE_EXPORT, payload: { columns, data } })
}

export const updateClientAccess = (formData) => async (dispatch) => {
	try {
		const result = await RecordServices.updateClientAccess(formData)
		if (result.data.status === "success") {
			dispatch({ type: UPDATE_CLIENT_ACCESS })
			dispatch(setAlert(result.data.response, "success", "UpdateRecord", 2000))
		}
		return true
	} catch (err) {
		dispatch({ type: RECORD_ERROR, payload: err.message })
		dispatch(setAlert("Failed to add new record!", "danger", "UpdateRecord"))
		return false
	}
}

export const filterRecords = (object, formData, params) => async (dispatch) => {
	try {
		const result = await RecordServices.filterRecords(
			object.filterUrl,
			formData,
			params
		)
		if (result.data.length < 1) {
			dispatch(
				setAlert("No records found with these filters", "danger", "AddFilters")
			)
			return false
		} else {
			dispatch({ type: GET_RECORDS_WITH_PAGINATION, payload: result.data })
			dispatch(setAlert("Successfully filtered", "success", "AddFilters", 1000))
			return true
		}
	} catch (err) {
		dispatch({ type: RECORD_ERROR, payload: err.message })
		dispatch(setAlert("Failed to filter records!", "danger", "AddFilters"))
		return false
	}
}

export const approveRecord = (object, selectedRecords) => async (dispatch) => {
	const formData = { selected_records: selectedRecords }
	try {
		const result = await RecordServices.approveRecords(
			object.approveUrl,
			formData
		)
		if (result.data.response.updated_jobs.length === 0) {
			dispatch(
				setAlert(
					"None of the selected records is approved!",
					"danger",
					"ApproveRecord",
					3000
				)
			)
		} else {
			const payload = {
				selected_records: result.data.response.updated_jobs,
				payload: { status: ["approved"] },
			}
			// const notApprovedJobs = selectedRecords.filter(
			// 	(id) => !result.data.response.updated_jobs.includes(id)
			// )

			const alertMessage =
				result.data.response.updated_jobs.length === selectedRecords.length
					? "All selected jobs are approved successfully."
					: "Some jobs did not get approved cause theye dont fulfil all requirments!"

			dispatch({ type: UPDATE_MANY_RECORDS, payload: payload })
			dispatch(setAlert(alertMessage, "success", "ApproveRecord", 3000))
			// if (notApprovedJobs.length > 0) dispatch(selectRecords(notApprovedJobs))
		}
		return true
	} catch (err) {
		dispatch({ type: RECORD_ERROR, payload: err.message })
		dispatch(
			setAlert("Failed to approve selected records!", "danger", "ApproveRecord")
		)
		return false
	}
}

export const getFuzzyJobs = (object, formData) => async (dispatch) => {
	try {
		dispatch(initRecords())
		const res = await RecordServices.getFuzzyJobsRecords(
			object.filterUrl,
			formData
		)
		dispatch({ type: GET_RECORDS, payload: res.data })
	} catch (err) {
		dispatch({ type: RECORD_ERROR, payload: err.message })
	}
}

export const clearAllRecords = (object) => async (dispatch) => {
	try {
		const res = await RecordServices.clearAllRecords(object.clearAllUrl)
		dispatch({ type: CLEAR_ALL_RECORDS, payload: {} })
		dispatch(setAlert(res.data.response, "success", "DeleteRecord", 2000))
		return true
	} catch (err) {
		dispatch({ type: RECORD_ERROR, payload: err.message })
		dispatch(setAlert("Failed to clear records!", "danger", "DeleteRecord"))
		return false
	}
}

export const getReports = (url, params) => async (dispatch) => {
	try {
		dispatch(initRecords())
		const res = await RecordServices.getReportsWithFilters(url, params)
		dispatch({ type: GET_RECORDS, payload: res.data })
	} catch (err) {
		dispatch({ type: RECORD_ERROR, payload: err.message })
	}
}
