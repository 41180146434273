/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import AsyncSelect from "react-select/async"
import { setAlert } from "../../../../actions/alert"
import { updateManyRecords, updateRecord } from "../../../../actions/record"
import ObjectServices from "../../../../services/object.service"
import Spinner from "../../../layout/Spinner"

const UpdateJob = ({
	object,
	record,
	setAlert,
	updateRecord,
	updateManyRecords,
	selectedRecords,
	rowsToUpdate,
	handleClose,
}) => {
	const navigate = useNavigate()
	const location = useLocation()
	const [loading, setLoading] = useState(false)
	const [formData, setFormData] = useState({
		job_title: "",
		job_type: "",
		company_working: "",
		company_final: "",
		sector_obj: {},
		date: "",
		location_working: "",
		location_final: "",
		salary: "",
		status: "",
		url: "",
		source: "",
	})
	const [jobTypeOptions, setJobTypeOptions] = useState([])

	const {
		company_working,
		company_final,
		sector_obj,
		date,
		job_title,
		job_type,
		location_final,
		location_working,
		salary,
		status,
		url,
		source,
	} = formData

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	// Get companies and locations options
	useEffect(() => {
		const getOptions = async () => {
			try {
				const jobTypesRes = await ObjectServices.getJobTypes()
				setJobTypeOptions(jobTypesRes.data)
			} catch (err) {
				if (rowsToUpdate === "one")
					setAlert(err.message, "danger", "UpdateRecord")
			}
		}
		getOptions()
	}, [])

	// Get job data
	useEffect(() => {
		if (rowsToUpdate === "one") {
			if (record !== null && record !== "") {
				setFormData({
					...record,
					job_type: record.job_type || "",
					date:
						record.date !== "" ? moment(record.date).format("YYYY-MM-DD") : "",
					status: record.status.length > 0 ? record.status[0] : "",
					source: record.source.length > 0 ? record.source[0] : "",
					sector_obj: record.sector_obj || {},
				})
			} else {
				navigate(-1)
			}
		}
	}, [record])

	const prepareData = () => {
		const formatStatus =
			rowsToUpdate === "one" && status === ""
				? ["pending"]
				: rowsToUpdate !== "one" && status === ""
				? ""
				: [status]
		const formatSource =
			rowsToUpdate === "one" && source === ""
				? ["direct"]
				: rowsToUpdate !== "one" && source === ""
				? ""
				: [source]
		return {
			company_working: company_working,
			company_final: company_final,
			date: date,
			job_title: job_title,
			job_type: job_type,
			job_type_obj: jobTypeOptions.find((jobT) => jobT.id === job_type),
			location_final: location_final,
			location_working: location_working,
			salary: salary,
			status: formatStatus,
			url: url,
			source: formatSource,
			sector: sector_obj,
		}
	}

	const checkEmptyData = () => {
		let result = true
		if (url === "") {
			setAlert("Missing url field!", "danger", "AddRecord")
			result = false
		}
		if (status === "approved") {
			if (job_title === "") {
				setAlert("Missing job title field!", "danger", "AddRecord")
				result = false
			}
			if (date === "") {
				setAlert("Missing posting date field!", "danger", "AddRecord")
				result = false
			}
			if (company_final === "") {
				setAlert("Missing company final field!", "danger", "AddRecord")
				result = false
			}
			if (location_final === "") {
				setAlert("Missing location final field!", "danger", "AddRecord")
				result = false
			}
		}
		return result
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		setLoading(true)
		if (rowsToUpdate === "one") {
			if (checkEmptyData() === true) {
				const res = await updateRecord(object, record.id, prepareData())
				if (res === true) {
					handleClose(false)
					navigate(`/records/${object.path}`)
				}
			}
		} else {
			const payload = {
				selected_records: selectedRecords,
				payload: prepareData(),
			}
			const res = await updateManyRecords(object, payload)
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		}
		setLoading(false)
	}

	const loadCompanyOptions = async (inputValue, callback) => {
		if (inputValue.length > 3) {
			try {
				const response = await ObjectServices.getCompanies({
					limit: "",
					skip: "",
					searchText: inputValue,
				})
				const options = response.data.results.map((company) => ({
					label: company.company_name,
					value: company.company_name,
					sector_obj: company.sector_obj,
				}))
				callback(options)
			} catch (error) {
				setAlert(error.message, "danger", "UpdateRecord")
				callback([])
			}
		}
	}

	const loadLocationOptions = async (inputValue, callback) => {
		if (inputValue.length > 3) {
			try {
				const response = await ObjectServices.getLocations({
					limit: "",
					skip: "",
					searchText: inputValue,
				})
				const options = response.data.results.map((location) => ({
					label: location.location_name,
					value: location.location_name,
				}))
				callback(options)
			} catch (error) {
				setAlert(error.message, "danger", "UpdateRecord")
				callback([])
			}
		}
	}

	const handleChangeCompany = (selectedOption) => {
		if (selectedOption) {
			setFormData({
				...formData,
				company_final: selectedOption.value,
				sector_obj: selectedOption.sector_obj,
			})
		} else {
			setFormData({
				...formData,
				company_final: "",
				sector_obj: {},
			})
		}
	}

	const handleChangeLocation = (selectedOption) => {
		if (selectedOption) {
			setFormData({
				...formData,
				location_final: selectedOption.value,
			})
		} else {
			setFormData({
				...formData,
				location_final: "",
			})
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<label>Posting Date</label>
			<input
				type='date'
				name='date'
				value={date}
				onChange={onChange}
				required={
					status === "approved" && rowsToUpdate === "one" ? true : false
				}
			></input>
			<label>Job Title</label>
			<input
				type='text'
				name='job_title'
				value={job_title}
				onChange={onChange}
				required={
					status === "approved" && rowsToUpdate === "one" ? true : false
				}
			></input>
			<label>Job Type</label>
			<select name='job_type' value={job_type} onChange={onChange}>
				<option value=''>--Select job type--</option>
				{jobTypeOptions.map((e) => (
					<option key={e.id} value={e.id}>
						{e.job_type}
					</option>
				))}
			</select>
			<label>Company Working</label>
			<input
				type='text'
				name='company_working'
				value={company_working}
				onChange={onChange}
			></input>
			<label>Company Final</label>
			<AsyncSelect
				cacheOptions
				loadOptions={loadCompanyOptions}
				onChange={(selectedOption) => handleChangeCompany(selectedOption)}
				value={{ label: company_final, value: company_final }}
				isClearable
			/>
			<label>Location Working</label>
			<input
				type='text'
				name='location_working'
				value={location_working}
				onChange={onChange}
			></input>
			<label>Location Final</label>
			<AsyncSelect
				cacheOptions
				loadOptions={loadLocationOptions}
				onChange={(selectedOption) => handleChangeLocation(selectedOption)}
				value={{ label: location_final, value: location_final }}
				isClearable
			/>
			<label>Salary</label>
			<input
				type='text'
				name='salary'
				value={salary}
				onChange={onChange}
			></input>
			<label>Url</label>
			<input
				type='url'
				name='url'
				value={url}
				onChange={onChange}
				required={rowsToUpdate === "one" ? true : false}
			></input>
			<label>Source</label>
			<select
				name='source'
				value={source}
				onChange={onChange}
				required={
					status === "approved" && rowsToUpdate === "one" ? true : false
				}
			>
				<option value='' disabled={rowsToUpdate === "one"}>
					--Select an option--
				</option>
				<option value='direct'>Direct</option>
				<option value='board'>Job board</option>
			</select>
			<label>Status</label>
			<select
				name='status'
				onChange={onChange}
				value={status}
				required={
					status === "approved" && rowsToUpdate === "one" ? true : false
				}
			>
				<option value='' disabled={rowsToUpdate === "one"}>
					--Select an option--
				</option>
				<option value='pending'>Pending</option>
				{rowsToUpdate === "one" && <option value='approved'>Approved</option>}
				<option value='expired'>Expired</option>
			</select>
			{loading ? (
				<Spinner spinnerSize='5em' />
			) : (
				<input type='submit' value='Submit'></input>
			)}
		</form>
	)
}

UpdateJob.prototype = {
	setAlert: PropTypes.func.isRequired,
	updateRecord: PropTypes.func.isRequired,
	updateManyRecords: PropTypes.func.isRequired,
	record: PropTypes.object.isRequired,
	object: PropTypes.object.isRequired,
	rowsToUpdate: PropTypes.string.isRequired,
	selectedRecords: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	record: state.record.record,
	selectedRecords: state.record.selectedRecords,
})

export default connect(mapStateToProps, {
	setAlert,
	updateRecord,
	updateManyRecords,
})(UpdateJob)
